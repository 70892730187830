import { map } from 'lodash';

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  PROFILE: '/profile',
  ORGANIZATIONS: '/organisations',
  MASTER: '/master',
  HOUSE_TYPES: '/house-types',
  HOUSE_SHAPES: '/house-shapes',
  ROOF_TYPES: '/roof-types',
  ROOF_EXTENSIONS: '/roof-extension',
  MATERIAL_CATEGORIES: '/material-categories',
  MATERIALS: '/materials',
  MATERIALS_QUESTIONS: '/materials-questions',
  HEATING_SYSTEMS: '/heating-systems',
  VISUALISATION: '/visualisation',
  EXPORTS: '/exports',
  FIELD_CONFIG: '/field-config',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  ORGANIZATIONS: 'Organisations',
  MASTER: 'Masters',
  HOUSE_TYPES: 'House Types',
  HOUSE_SHAPES: 'House Shapes',
  ROOF_TYPES: 'Roof Types',
  ROOF_EXTENSIONS: 'Roof Extensions',
  MATERIAL_CATEGORIES: 'Material Categories',
  MATERIALS: 'Materials',
  MATERIALS_QUESTIONS: 'Materials Questions',
  HEATING_SYSTEMS: 'Heating Systems',
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
  EXPORTS: 'Exports',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super Admin',
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^([A-Za-z0-9_\-.+])+@(?!(?:[A-Za-z0-9_\-.]+\.)?([A-Za-z]{2,4})\.\2)(?!.*\.\.)[A-Za-z0-9_\-.]+\.([A-Za-z]{2,4})$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  POSITIVE_NUMBERS: /^(0|[1-9]\d*)$/,
  PRICE: /^(?!0+(\.0*)?$)\d+(\.\d{1,2})?$/,
  NOT_DIGIT_CHARACTER: /[^\d.-]/g,
  MASK_4DIGIT: /.(?=.{4,}$)/g,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const NUMBER_FIELD_TYPES = {
  PRICE: 'PRICE',
  BORDER_RADIUS: 'BORDER_RADIUS',
  HEIGHT_WIDTH: 'HEIGHT_WIDTH',
};

export const SORT = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DEFAULT_SORT_CONFIG = {
  sortBy: SORT?.DESC,
  sortOn: 'createdAt',
};

export const USER_ROLES = {
  SUPER_ADMIN: { label: 'Super Admin', key: 'SUPER_ADMIN' },
};

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const TABLE_HEIGHT = 225;
export const TABLE_HEIGHT_SEARCH = 395;

export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'gif', 'svg'];

export const DATE_FORMATS = {
  REGULAR: 'DD/MM/YYYY',
  WITH_TIME: 'DD/MM/YYYY | h:mm A',
};

export const TIME_FORMATS = {
  REGULAR: 'h:mm A',
};

export const MAX_IMAGE_SIZE = 15 * 1024 * 1024;

export const SIDEBAR_ADMIN_DROPDOWNS = ['master'];

export const PERMISSIONS_KEY = {
  OVERVIEW: 'OVERVIEW',
};

export const PERMISSION_TYPE = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const TABS = {
  ORGANIZATION: {
    key: 'details',
    label: 'Details',
  },
  ORGANIZATION_CONFIGURATION: {
    key: 'configuration',
    label: 'Configuration',
  },
  ORGANIZATION_EMAIL_CONFIG: {
    key: 'email-config',
    label: 'Email Config',
  },
  ORGANIZATION_UI_LABELS: {
    key: 'ui-labels',
    label: 'Ui Labels',
  },
  GENERAL: {
    key: 'general',
    label: 'General',
  },
  VISUALISATION: {
    key: 'visualisation',
    label: 'Visualisation',
  },
  FIELD_CONFIG: {
    key: 'field-config',
    label: 'Field Config',
  },
  MASTER: {
    key: 'master',
    label: 'Masters',
  },
};

export const LANGUAGES = [{ label: 'English', value: 'en' }];

export const FONT_FAMILY = [
  {
    label: 'Roboto',
    value: 'ROBOTO',
  },
  {
    label: 'Montserrat',
    value: 'MONTSERRAT',
  },
  {
    label: 'Open Sans',
    value: 'OPEN_SANS',
  },
  {
    label: 'Playfair Display',
    value: 'PLAYFAIR_DISPLAY',
  },
  {
    label: 'Nunito Sans',
    value: 'NUNITO_SANS',
  },
  {
    label: 'Poppins',
    value: 'POPPINS',
  },
  {
    label: 'Plus Jakarta Sans',
    value: 'PLUS_JAKARTA_SANS',
  },
  {
    label: 'Avenir Next LT Pro Regular',
    value: 'AVENIR_NEXT_LT_PRO_REGULAR',
  },
  {
    label: 'Chromatophore',
    value: 'CHROMATOPHORE',
  },
  {
    label: 'Lora',
    value: 'LORA',
  },
  {
    label: 'Px-Grotesk',
    value: 'PX_GROTESK',
  },
  {
    label: 'Tiempos',
    value: 'TIEMPOS',
  },
];

export const EMAIL_OPTIONS = [
  {
    label: 'Do not display',
    value: 0,
  },
  {
    label: 'At the beginning',
    value: 1,
  },
  {
    label: 'Before recommendation',
    value: 5,
  },
  {
    label: 'After recommendation',
    value: 6,
  },
];

export const THEME_CONFIG = [
  {
    title: 'Primary',
    colors: [
      {
        title: 'Main',
        name: 'primary',
        value: '#407076',
        isDisabled: false,
      },
      {
        title: 'Primary tint 1',
        name: 'primaryTint1',
        value: '#F1F7F7',
        isDisabled: true,
      },
      {
        title: 'Primary tint 2',
        name: 'primaryTint2',
        value: '#99C2C7',
        isDisabled: true,
      },
      {
        title: 'Primary shade 1',
        name: 'primaryShade1',
        value: '#28464A',
        isDisabled: true,
      },
      {
        title: 'Primary shade 2',
        name: 'primaryShade2',
        value: '#101C1E',
        isDisabled: true,
      },
    ],
  },
  {
    title: 'Accent',
    colors: [
      {
        title: 'Accent',
        name: 'accent',
        value: '#F5B753',
        isDisabled: false,
      },
      {
        title: 'Accent tint 1',
        name: 'accentTint1',
        value: '#FCEDD4',
        isDisabled: true,
      },
      {
        title: 'Accent tint 2',
        name: 'accentTint2',
        value: '#F9D293',
        isDisabled: true,
      },
      {
        title: 'Accent Shade 1',
        name: 'accentShade1',
        value: '#F2A323',
        isDisabled: true,
      },
      {
        title: 'Accent shade 2',
        name: 'accentShade2',
        value: '#D6890C',
        isDisabled: true,
      },
    ],
  },
  {
    title: 'Background',
    colors: [
      {
        title: 'Background 1',
        name: 'background1',
        value: '#FFFFFF',
        isDisabled: false,
      },
      {
        title: 'Background 2',
        name: 'background2',
        value: '#F2F4F7',
        isDisabled: false,
      },
      {
        title: 'Background 3',
        name: 'background3',
        value: '#FFFFFF',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Stroke',
    colors: [
      {
        title: 'Light',
        name: 'strokeLight',
        value: '#F5F5F5',
        isDisabled: false,
      },
      {
        title: 'Dark',
        name: 'strokeDark',
        value: '#D6D6D6',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Text',
    colors: [
      {
        title: 'Light',
        name: 'textLight',
        value: '#B1B3B7',
        isDisabled: false,
      },
      {
        title: 'Medium',
        name: 'textMedium',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Dark',
        name: 'textDark',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Extra',
        name: 'textExtra',
        value: '#000000',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'System',
    colors: [
      {
        title: 'Green',
        name: 'systemGreen',
        value: '#3bb665',
        isDisabled: false,
      },
      {
        title: 'Red',
        name: 'systemRed',
        value: '#e64e2c',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Progress Bar',
    colors: [
      {
        title: 'Filled Progress',
        name: 'progressFill',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Unfilled Progress',
        name: 'progressUnFill',
        value: '#d5d7d7',
        isDisabled: false,
      },
      {
        title: 'Progress Active Text',
        name: 'progressFillText',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Progress UnActive Text',
        name: 'progressUnFillText',
        value: '#c6c8c8',
        isDisabled: false,
      },
      {
        title: 'Filled Circle Markers',
        name: 'progressFilledCircle',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'UnFilled Circle Markers',
        name: 'progressUnFilledCircle',
        value: '#ffffff',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Tooltip Icon',
    colors: [
      {
        title: 'Text',
        name: 'tooltipText',
        value: '#ffffff',
        isDisabled: false,
      },
      {
        title: 'Background',
        name: 'tooltipBg',
        value: '#00805d',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Checkbox Styling',
    colors: [
      {
        title: 'Checked Bg',
        name: 'checkboxCheckBg',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Unchecked Bg',
        name: 'checkboxUncheckBg',
        value: '#ffffff',
        isDisabled: false,
      },
      {
        title: 'Check Icon',
        name: 'checkboxCheckIcon',
        value: '#ffffff',
        isDisabled: false,
      },
      {
        title: 'Hover',
        name: 'checkboxHover',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Border',
        name: 'checkboxBorder',
        value: '#d9d9d9',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Radio Styling',
    colors: [
      {
        title: 'Checked Bg',
        name: 'radioCheckBg',
        value: '#ffffff',
        isDisabled: false,
      },
      {
        title: 'Unchecked Bg',
        name: 'radioUnCheckBg',
        value: '#ffffff',
        isDisabled: false,
      },
      {
        title: 'Checked Color',
        name: 'radioCheckedColor',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Hover',
        name: 'radioHover',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Border',
        name: 'radioBorder',
        value: '#d9d9d9',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Slider Styling',
    colors: [
      {
        title: 'Primary',
        name: 'sliderPrimary',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Rail Bg',
        name: 'sliderHoverBg',
        value: '#e8e8e8',
        isDisabled: false,
      },
      {
        title: 'Track Hover Bg',
        name: 'sliderTrackHoverBg',
        value: '#005a42',
        isDisabled: false,
      },
      {
        title: 'Circle',
        name: 'sliderCircle',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Circle Border',
        name: 'sliderCircleBorder',
        value: '#99C2C7',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Material Styling',
    colors: [
      {
        title: 'Material Box Headers Open',
        name: 'materiaBoxHeadersOpen',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Material Box Headers Closed',
        name: 'materiaBoxHeadersClosed',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Material Box Description Open',
        name: 'materialBoxDescriptionOpen',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Material Box Description Closed',
        name: 'materialBoxDescriptionClosed',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Material Box Number Text',
        name: 'materialBoxNumberText',
        value: '#407076',
        isDisabled: false,
      },
      {
        title: 'Material Box Number Border',
        name: 'materialBoxNumberBorder',
        value: '#407076',
        isDisabled: false,
      },
      {
        title: 'Material Box Number Background',
        name: 'materialBoxNumberBackground',
        value: '#F1F7F7',
        isDisabled: false,
      },
      {
        title: 'Materials Description',
        name: 'materialsDescription',
        value: '#626262',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Result Styling',
    colors: [
      {
        title: 'Result Main Header',
        name: 'resultMainHeader',
        value: '#101C1E',
        isDisabled: false,
      },
      {
        title: 'Result Main Description',
        name: 'resultMainDescription',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Result Mini Header',
        name: 'resultMiniHeader',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Result Mini Description',
        name: 'resultMiniDescription',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Result Card Divider',
        name: 'resultCardDivider',
        value: '#F5F5F5',
        isDisabled: false,
      },
      {
        title: 'Result Count Background',
        name: 'resultCountBg',
        value: '#FFFFFF',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Sort Btn Styling',
    colors: [
      {
        title: 'Icon',
        name: 'sortIcon',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Text',
        name: 'sortText',
        value: '#18191A',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Customisation Styling',
    colors: [
      {
        title: 'Customise Text',
        name: 'closeCustomiseText',
        value: '#00805d',
        isDisabled: false,
      },
      {
        title: 'Close Text',
        name: 'closeText',
        value: '#00805d',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Recommendation Result',
    colors: [
      {
        title: 'Background',
        name: 'resultBg',
        value: '#F2F4F7',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Tell Me More',
    colors: [
      {
        title: 'Text',
        name: 'tellMeMoreText',
        value: '#00805d',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Question Info',
    colors: [
      {
        title: 'Text',
        name: 'questionInfoText',
        value: '#00805d',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Change Address',
    colors: [
      {
        title: 'Text',
        name: 'changeAddressText',
        value: '#d6890c',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Disclaimer Text',
    colors: [
      {
        title: 'Text',
        name: 'disclaimerText',
        value: '#505353',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Suggested Upgrades Text',
    colors: [
      {
        title: 'Text',
        name: 'suggestedUpgradeText',
        value: '#101c1e',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Customisation Panel Styling',
    colors: [
      {
        title: 'Description Text',
        name: 'customisationDescText',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Header Text',
        name: 'customisationHeaderText',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Text',
        name: 'customisationText',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Subtext',
        name: 'customisationSubText',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Mobile Background',
        name: 'customisationMobileBg',
        value: '#FFFFFF',
        isDisabled: false,
      },
      {
        title: 'Mobile Text',
        name: 'customisationMobileText',
        value: '#18191a',
        isDisabled: false,
      },
      {
        title: 'Mobile Subtext',
        name: 'customisationMobileSubtext',
        value: '#626262',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Upgrades Text',
    colors: [
      {
        title: 'Text',
        name: 'upgradesText',
        value: '#18191A',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Selected Roof Background',
    colors: [
      {
        title: 'Background',
        name: 'selectedRoofBg',
        value: '#F1F7F7',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Icon Styling',
    colors: [
      {
        title: 'Address',
        name: 'iconAddress',
        value: '#101c1e',
        isDisabled: false,
      },
      {
        title: 'Bill Savings',
        name: 'iconBillSavings',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Carbon Savings',
        name: 'iconCarbonSavings',
        value: '#626262',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Plan Button Heart Styling',
    colors: [
      {
        title: 'Heart Unfilled Border',
        name: 'heartUnfilledBorder',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Heart Hover Unfilled Border',
        name: 'heartHoverUnfilledBorder',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Mobile Heart Unfilled Border',
        name: 'heartMobileUnfilledBorder',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Mobile Heart Hover Unfilled Border',
        name: 'heartMobileHoverUnfilledBorder',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Heart Filled Border',
        name: 'heartFilledBorder',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Heart Filled Hover Border',
        name: 'heartFilledHoverBorder',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Mobile Heart Filled Border',
        name: 'heartMobileFilledBorder',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Mobile Heart Filled Hover Border',
        name: 'heartMobileFilledHoverBorder',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Heart Filled',
        name: 'heartFilled',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Heart Unfilled',
        name: 'heartUnfilled',
        value: 'transparent',
        isDisabled: false,
      },
      {
        title: 'Mobile Heart Filled',
        name: 'heartMobileFilled',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Mobile Heart Unfilled',
        name: 'heartMobileUnfilled',
        value: 'transparent',
        isDisabled: false,
      },
      {
        title: 'Heart Hover Filled',
        name: 'heartHoverFilled',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Heart Hover Unfilled',
        name: 'heartHoverUnfilled',
        value: 'transparent',
        isDisabled: false,
      },
      {
        title: 'Mobile Heart Hover Filled',
        name: 'heartMobileHoverFilled',
        value: '#e64e2c',
        isDisabled: false,
      },
      {
        title: 'Mobile Heart Hover Unfilled',
        name: 'heartMobileHoverUnfilled',
        value: 'transparent',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Grant Fundable Styling',
    colors: [
      {
        title: 'Text',
        name: 'grantFundableText',
        value: '#1e1f1f',
        isDisabled: false,
      },
      {
        title: 'Background',
        name: 'grantFundableBackground',
        value: '#ffbb56',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Energy Bill Saving Description Styling',
    colors: [
      {
        title: 'Text Link',
        name: 'billSavingDescTextLink',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Hover Text Link',
        name: 'billSavingDescHoverTextLink',
        value: '#626262',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Total Cost Description Styling',
    colors: [
      {
        title: 'Text',
        name: 'totalCostDescText',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Hover Text',
        name: 'totalCostDescHoverText',
        value: '#626262',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Measure Styling',
    colors: [
      {
        title: 'Title Text',
        name: 'measuresHeaderText',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Cost Text',
        name: 'measureCostText',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Description Text',
        name: 'measureDescriptionText',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Background',
        name: 'measureBackground',
        value: '#FFFFFF',
        isDisabled: false,
      },
      {
        title: 'Estimated Savings Text',
        name: 'measureEstimatedSavingsText',
        value: '#626262',
        isDisabled: false,
      },
      {
        title: 'Estimated Savings Value Text',
        name: 'measureEstimatedSavingsValueText',
        value: '#D6890C',
        isDisabled: false,
      },
      {
        title: 'Estimated Savings Percentage Text',
        name: 'measureEstimatedSavingsPercentageText',
        value: '#3bb665',
        isDisabled: false,
      },
      {
        title: 'Estimated Savings Icon',
        name: 'measureEstimatedSavingsIcon',
        value: '#3bb665',
        isDisabled: false,
      },
    ],
  },
  {
    title: 'Recommendation Popup Text Styling',
    colors: [
      {
        title: 'Title',
        name: 'recommendationPopupTitle',
        value: '#18191A',
        isDisabled: false,
      },
      {
        title: 'Description',
        name: 'recommendationPopupDescription',
        value: '#626262',
        isDisabled: false,
      },
    ],
  },
];

export const CONFIG_TYPE = {
  COLOR: 'COLOR',
  INPUT_TEXT: 'INPUT_TEXT',
  INPUT_NUM: 'INPUT_NUM',
  SELECT: 'SELECT',
  SWITCH: 'SWITCH',
};

export const JOIN_WAIT_LIST_OPTIONS = {
  DO_NOT_DISPLAY: {
    label: 'Do not display',
    value: 'DO_NOT_DISPLAY',
  },
  TOP: {
    label: 'Top',
    value: 'TOP',
  },
  BOTTOM: {
    label: 'Bottom',
    value: 'BOTTOM',
  },
  BOTH: {
    label: 'Both',
    value: 'BOTH',
  },
};

export const VISIBILITY_OPTIONS = {
  DO_NOT_DISPLAY: {
    label: 'Do not display',
    value: 'DO_NOT_DISPLAY',
  },
  EPC_FLOW: {
    label: 'Display during epc flow',
    value: 'EPC_FLOW',
  },
  NON_EPC_FLOW: {
    label: 'Display during non-epc flow',
    value: 'NON_EPC_FLOW',
  },
  BOTH_FLOWS: {
    label: 'Display on both flows',
    value: 'BOTH_FLOWS',
  },
};

export const TOOLTIP_ICON_OPTIONS = {
  QUESTION_MARK: {
    label: 'Question Mark Icon (?)',
    value: 'QUESTION_MARK_ICON',
  },
  INFO: { label: 'Info Icon (i)', value: 'INFO_ICON' },
};

export const BUTTON_CONFIG = [
  {
    title: 'Primary Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'primaryButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'primaryHoverButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'primaryButtonBg',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'primaryHoverButtonBg',
        value: '#005a42',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'primaryButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'primaryHoverButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'primaryButtonRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Secondary Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'secondaryButtonColor',
        value: '#ac8239',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'secondaryButtonHoverColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'secondaryButtonBg',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'secondaryButtonHoverBg',
        value: '#ac8239',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'secondaryButtonBorder',
        value: '#ac8239',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'secondaryButtonHoverBorder',
        value: '#ac8239',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'secondaryRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Default Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'defaultColor',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'defaultHoverColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'defaultButtonBg',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'defaultHoverBg',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'defaultBorder',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'defaultHoverBorder',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'defaultRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Wait list Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'waitListButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'waitListHoverButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'waitListButtonBg',
        value: 'rgb(255 255 255 / 50%)',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
        isOtherColor: true,
      },
      {
        title: 'Hover Bg',
        name: 'waitListHoverButtonBg',
        value: 'rgb(255 255 255 / 40%)',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
        isOtherColor: true,
      },
      {
        title: 'Border',
        name: 'waitListBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'waitListHoverButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'waitListButtonRadius',
        value: '12',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Book Your Survey Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'bookSurveyButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'bookSurveyHoverButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'bookSurveyButtonBg',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'bookSurveyHoverButtonBg',
        value: '#005a42',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'bookSurveyButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'bookSurveyHoverButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'bookSurveyButtonRadius',
        value: '12',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Get Your Green Home Send Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'sendButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'sendHoverButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'sendButtonBg',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'sendHoverButtonBg',
        value: '#005a42',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'sendButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'sendHoverButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'sendButtonRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Book Survey Email Send Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'emailSendButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'emailSendHoverButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'emailSendButtonBg',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'emailSendHoverButtonBg',
        value: '#005a42',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'emailSendButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'emailSendHoverButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'emailSendButtonRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Arrange New EPC Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'arrangeEPCButtonColor',
        value: '#ac8239',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'arrangeEPCButtonHoverColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'arrangeEPCButtonBg',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'arrangeEPCButtonHoverBg',
        value: '#ac8239',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'arrangeEPCButtonBorder',
        value: '#ac8239',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'arrangeEPCButtonHoverBorder',
        value: '#ac8239',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'arrangeEPCRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Plan Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'planButtonColor',
        value: '#080000',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'planButtonHoverColor',
        value: '#080000',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Mobile Text',
        name: 'planButtonMobileColor',
        value: '#080000',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Mobile Hover Text',
        name: 'planButtonMobileHoverColor',
        value: '#080000',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'planButtonBg',
        value: '#f2f4f7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'planButtonHoverBg',
        value: '#f2f4f7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Mobile Background',
        name: 'planButtonMobileBg',
        value: '#f2f4f7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Mobile Hover Bg',
        name: 'planButtonMobileHoverBg',
        value: '#f2f4f7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'planButtonBorder',
        value: '#e8e8e8',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'planButtonHoverBorder',
        value: '#e8e8e8',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Mobile Border',
        name: 'planButtonMobileBorder',
        value: '#e8e8e8',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Mobile Hover Border',
        name: 'planButtonMobileHoverBorder',
        value: '#e8e8e8',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'planButtonRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Disable Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'disableButtonColor',
        value: '#c6c8c8',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'disableButtonBg',
        value: '#d5d7d7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'disableButtonBorder',
        value: '#d5d7d7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
    ],
  },
  {
    title: 'Customisation Preferences & Start Over Cancel Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'customisationCancelColor',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'customisationCancelHoverColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'customisationCancelButtonBg',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'customisationCancelHoverBg',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'customisationCancelBorder',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'customisationCancelHoverBorder',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'customisationCancelRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Visit Supplier Btn Styling',
    configs: [
      {
        title: 'Icon',
        name: 'visitSupplierIconColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Icon',
        name: 'visitSupplierHoverIconColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Text',
        name: 'visitSupplierButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'visitSupplierHoverButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'visitSupplierButtonBg',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'visitSupplierHoverButtonBg',
        value: '#005a42',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'visitSupplierButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'visitSupplierHoverButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'visitSupplierButtonRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Send Email Popup Btn Styling',
    configs: [
      {
        title: 'Text',
        name: 'sendEmailPopupButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'sendEmailPopupHoverButtonColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'sendEmailPopupButtonBg',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Bg',
        name: 'sendEmailPopupHoverButtonBg',
        value: '#005a42',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'sendEmailPopupButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'sendEmailPopupHoverButtonBorder',
        value: 'transparent',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'sendEmailPopupButtonRadius',
        value: '4',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
];

export const WAIT_LIST_CONFIG = [
  {
    title: 'Styling',
    configs: [
      {
        title: 'Text',
        name: 'waitListTextColor',
        value: '#ffffff',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        placeHolder: 'Enter Background',
        name: 'waitListGradient',
        value:
          'linear-gradient(67deg, rgba(122,33,201,1) 5%, rgba(72,120,205,1) 48%, rgba(0,230,217,1) 75%)',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_TEXT,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'waitListCardBorderRadius',
        value: '16',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Position',
        placeHolder: 'Select Position',
        options: map(JOIN_WAIT_LIST_OPTIONS, (item) => item),
        name: 'waitListCardPosition',
        value: JOIN_WAIT_LIST_OPTIONS?.DO_NOT_DISPLAY?.value,
        isDisabled: false,
        type: CONFIG_TYPE?.SELECT,
      },
    ],
  },
];

export const BOOK_SURVEY_CONFIG = [
  {
    title: 'Result Styling',
    configs: [
      {
        title: 'Sticky On Top (Mobile)',
        name: 'resultStickyOnTopMobile',
        value: true,
        isDisabled: false,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Sticky On Top (Desktop)',
        name: 'resultStickyOnTopDesktop',
        value: true,
        isDisabled: false,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Align Text To Left (Mobile)',
        name: 'resultAlignTextLeft',
        value: true,
        isDisabled: true,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Horizontal Result (Mobile)',
        name: 'resultHorizontal',
        value: true,
        isDisabled: true,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Full Width for Result Description (Desktop)',
        name: 'resultDescriptionFullWidth',
        value: true,
        isDisabled: true,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Carbon metric mini description % value visible',
        name: 'carbonMetricValueVisible',
        value: true,
        isDisabled: true,
        type: CONFIG_TYPE?.SWITCH,
      },
    ],
  },
  {
    title: 'Book Survey Styling',
    configs: [
      {
        title: 'Book Survey Visible (Desktop)',
        name: 'bookSurveyVisibleDesktop',
        value: false,
        isDisabled: false,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Book Survey Visible (Mobile)',
        name: 'bookSurveyVisibleMobile',
        value: false,
        isDisabled: false,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Text',
        name: 'bookSurveyTextColor',
        value: '#626262',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'bookSurveyBgColor',
        value: '#FFFFFF',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'bookSurveyBorderColor',
        value: '#E7E7E7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
    ],
  },
  {
    title: 'Send Email Styling',
    configs: [
      {
        title: 'Title',
        name: 'sendEmailTitleColor',
        value: '#101C1E',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Description',
        name: 'sendEmailDescriptionColor',
        value: '#626262',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'sendEmailBgColor',
        value: '#F2F4F7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'No Thanks Text',
        name: 'sendEmailThanksTextLinkColor',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'No Thanks Text Hover',
        name: 'sendEmailThanksTextHoverLinkColor',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'By Opting In Text',
        name: 'sendEmailByOptingInColor',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Privacy Policy Text Link',
        name: 'sendEmailPrivacyPolicyColor',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Privacy Policy Hover Text Link',
        name: 'sendEmailPrivacyPolicyHoverColor',
        value: '#00805d',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Opt Me In Checkbox Text',
        name: 'optMeInCheckboxText',
        value: '#505353',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Email Error Message Color',
        name: 'emailErrorMessageColor',
        value: '#e64e2c',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Popup Title',
        name: 'sendEmailPopupTitle',
        value: '#000000',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Popup Description',
        name: 'sendEmailPopupDescription',
        value: '#505353',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Book Survey Visible',
        name: 'sendEmailBookSurveyVisible',
        value: false,
        isDisabled: false,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Opt me Visible',
        name: 'sendEmailOptMeVisible',
        value: true,
        isDisabled: true,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Align text to left (Mobile)',
        name: 'sendEmailLeftAlignment',
        value: false,
        isDisabled: true,
        type: CONFIG_TYPE?.SWITCH,
      },
    ],
  },
  {
    title: 'Book Survey Send Email Input Styling',
    configs: [
      {
        title: 'Text',
        name: 'inputSendEmailBookSurveyTextColor',
        value: '#000000',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Placeholder',
        name: 'inputSendEmailBookSurveyPlaceholderColor',
        value: '#B1B3B7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'inputSendEmailBookSurveyBackgroundColor',
        value: '#FFFFFF',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'inputSendEmailBookSurveyBorderColor',
        value: '#F5F5F5',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'inputSendEmailBookSurveyHoverBorderColor',
        value: '#407076',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'inputSendEmailBookSurveyBorderRadius',
        value: '6',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Border Width (px)',
        placeHolder: 'Enter Border Width (px)',
        name: 'inputSendEmailBookSurveyBorderWidth',
        value: '1',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },

  {
    title: 'Send Email Input Styling',
    configs: [
      {
        title: 'Form Label Text',
        name: 'formLabelText',
        value: '#505353',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Text',
        name: 'inputSendEmailTextColor',
        value: '#000000',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Placeholder',
        name: 'inputSendEmailPlaceholderColor',
        value: '#B1B3B7',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'inputSendEmailBackgroundColor',
        value: '#FFFFFF',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'inputSendEmailBorderColor',
        value: '#F5F5F5',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Border',
        name: 'inputSendEmailHoverBorderColor',
        value: '#407076',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Radius (px)',
        placeHolder: 'Enter Border Radius (px)',
        name: 'inputSendEmailBorderRadius',
        value: '6',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Border Width (px)',
        placeHolder: 'Enter Border Width (px)',
        name: 'inputSendEmailBorderWidth',
        value: '1',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
];

export const LAYOUT_CONFIG = [
  {
    title: 'Header Styling',
    configs: [
      {
        title: 'Header Visible',
        name: 'headerVisible',
        value: false,
        isDisabled: false,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Background',
        name: 'headerBgColor',
        value: '#F5F5F5',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'headerBorderColor',
        value: '#F5F5F5',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Width (px)',
        placeHolder: 'Enter Border Width (px)',
        name: 'headerBorderWidth',
        value: '1',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Header Height Desktop (px)',
        placeHolder: 'Enter Header Height Desktop (px)',
        name: 'headerHeightDesktop',
        value: '88',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Header Height Mobile (px)',
        placeHolder: 'Enter Header Height Mobile (px)',
        name: 'headerHeightMobile',
        value: '54',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Header Logo Width Desktop (px)',
        placeHolder: 'Enter Header Logo Width Desktop (px)',
        name: 'headerLogoWidthDesktop',
        value: '40',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Header Logo Width Mobile (px)',
        placeHolder: 'Enter Header Logo Width Mobile (px)',
        name: 'headerLogoWidthMobile',
        value: '40',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
  {
    title: 'Footer Styling',
    configs: [
      {
        title: 'Footer Visible',
        name: 'footerVisible',
        value: false,
        isDisabled: false,
        type: CONFIG_TYPE?.SWITCH,
      },
      {
        title: 'Text',
        name: 'footerTextColor',
        value: '#F0FFFF',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Hover Text',
        name: 'footerHoverTextColor',
        value: '#F0FFFF',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Background',
        name: 'footerBgColor',
        value: '#F5F5F5',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border',
        name: 'footerBorderColor',
        value: '#F5F5F5',
        isDisabled: false,
        type: CONFIG_TYPE?.COLOR,
      },
      {
        title: 'Border Width (px)',
        placeHolder: 'Enter Border Width (px)',
        name: 'footerBorderWidth',
        value: '1',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Footer Height Desktop (px)',
        placeHolder: 'Enter Footer Height Desktop (px)',
        name: 'footerHeightDesktop',
        value: '66',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
      {
        title: 'Footer Height Mobile (px)',
        placeHolder: 'Enter Footer Height Mobile (px)',
        name: 'footerHeightMobile',
        value: '66',
        isDisabled: false,
        type: CONFIG_TYPE?.INPUT_NUM,
      },
    ],
  },
];

export const FLOW_VISIBILITY_CONFIG = [
  {
    title: 'Update EPC Configs',
    configs: [
      {
        title: 'Visibility',
        placeHolder: 'Select Visibility',
        options: map(VISIBILITY_OPTIONS, (item) => item),
        name: 'updateEpcVisibility',
        value: VISIBILITY_OPTIONS?.BOTH_FLOWS?.value,
        isDisabled: false,
        type: CONFIG_TYPE?.SELECT,
      },
    ],
  },
  {
    title: 'Map Configs',
    configs: [
      {
        title: 'Visibility',
        placeHolder: 'Select Visibility',
        options: map(VISIBILITY_OPTIONS, (item) => item),
        name: 'updateMapVisibility',
        value: VISIBILITY_OPTIONS?.BOTH_FLOWS?.value,
        isDisabled: false,
        type: CONFIG_TYPE?.SELECT,
      },
    ],
  },
  {
    title: 'Recommendation Popup Configs',
    configs: [
      {
        title: 'Visibility',
        placeHolder: 'Select Visibility',
        options: map(VISIBILITY_OPTIONS, (item) => item),
        name: 'updateRecommendationPopupVisibility',
        value: VISIBILITY_OPTIONS?.BOTH_FLOWS?.value,
        isDisabled: false,
        type: CONFIG_TYPE?.SELECT,
      },
    ],
  },
];

export const TOOLTIP_ICON_CONFIG = [
  {
    title: 'Configs',
    configs: [
      {
        title: 'Icon type',
        placeHolder: 'Select Icon',
        options: map(TOOLTIP_ICON_OPTIONS, (item) => item),
        name: 'tooltipIconConfig',
        value: TOOLTIP_ICON_OPTIONS?.QUESTION_MARK?.value,
        isDisabled: false,
        type: CONFIG_TYPE?.SELECT,
      },
    ],
  },
];

export const PRIMARY_COLORS = {
  primaryTint1: 95.7,
  primaryTint2: 69,
  primaryShade1: 22.4,
  primaryShade2: 9,
};

export const ACCENT_COLORS = {
  accentTint1: 91,
  accentTint2: 77.6,
  accentShade1: 54.3,
  accentShade2: 44.3,
};

export const QUESTION_TYPE = [
  {
    label: 'Text',
    value: 'TEXT',
  },
  {
    label: 'Select',
    value: 'SELECT',
  },
  {
    label: 'Radio',
    value: 'RADIO',
  },
  {
    label: 'Checkbox',
    value: 'CHECKBOX',
  },
];

export const MATERIAL_TABS = {
  MATERIAL_CATEGORIES: {
    key: 'material-categories',
    label: 'Material Category',
  },
  MATERIALS: {
    key: 'materials',
    label: 'Materials',
  },
};

export const UI_LABEL_TYPES = [
  {
    label: 'String',
    value: 'STRING',
  },
  {
    label: 'HTML',
    value: 'HTML',
  },
];

export const RECOMMENDATION_TEMPLATE_KEY = 'RECOMMENDATION_REPORT';

export const CHART_X_AXIS = {
  ADDRESS: { label: 'Collect address', value: 'ADDRESS' },
  HOME: { label: 'Home', value: 'HOME' },
  BUILDING: { label: 'Building', value: 'BUILDING' },
  MATERIALS: { label: 'Materials', value: 'MATERIALS' },
  HEATING: { label: 'Heating', value: 'HEATING' },
  EMAIL: { label: 'Email', value: 'EMAIL' },
  RECOMMENDATION: { label: 'Recommendation', value: 'RECOMMENDATION' },
  UPGRADES: { label: 'Upgrades', value: 'UPGRADES' },
};

export const EXPORT_JOBS_STATUS = {
  PENDING: { text: 'Pending', color: 'yellow', value: 'PENDING' },
  EXPORTING: { text: 'Exporting', color: 'blue', value: 'EXPORTING' },
  COMPLETED: { text: 'Completed', color: 'green', value: 'COMPLETED' },
  FAILED: { text: 'Failed', color: 'red', value: 'FAILED' },
};

export const EXPORTS = {
  ALL: {
    value: 'ALL',
    label: 'All',
  },
  COMPANY_CONFIGS: {
    value: 'COMPANY_CONFIGS',
    label: 'Company Configs',
  },
  DIY_MEASURES: {
    value: 'DIY_MEASURES',
    label: 'DIY Measures',
  },
  OTHER_MEASURES: {
    value: 'OTHER_MEASURES',
    label: 'Other Measures',
  },
};

export const CONFIG_DURATION_OPTIONS = {
  MONTHLY: { label: 'Monthly', value: 'MONTHLY' },
  YEARLY: { label: 'Yearly', value: 'YEARLY' },
};

export const EMPTY_STATE_IMAGES_KEYS = {
  RECOMMENDATIONS_PAGE: 'RECOMMENDATIONS_PAGE',
  UPGRADES_PAGE: 'UPGRADES_PAGE',
};

export const SORT_OPTIONS = {
  PAYBACK_TIME_HIGH_TO_LOW: {
    label: 'Payback Time: High to Low',
    value: 'PAYBACK_TIME_HIGH_TO_LOW',
    sortOn: 'recommendationPaybackTime',
    sortBy: 'DESC',
  },
  PAYBACK_TIME_LOW_TO_HIGH: {
    label: 'Payback Time: Low to High',
    value: 'PAYBACK_TIME_LOW_TO_HIGH',
    sortOn: 'recommendationPaybackTime',
    sortBy: 'ASC',
  },
  ANNUAL_SAVINGS_HIGH_TO_LOW: {
    label: 'Annual Savings: High to Low',
    value: 'ANNUAL_SAVINGS_HIGH_TO_LOW',
    sortOn: 'recommendationCostSaving',
    sortBy: 'DESC',
  },
  ANNUAL_SAVINGS_LOW_TO_HIGH: {
    label: 'Annual Savings: Low to High',
    value: 'ANNUAL_SAVINGS_LOW_TO_HIGH',
    sortOn: 'recommendationCostSaving',
    sortBy: 'ASC',
  },
  INSTALLATION_COST_HIGH_TO_LOW: {
    label: 'Installation Cost: High to Low',
    value: 'INSTALLATION_COST_HIGH_TO_LOW',
    sortOn: 'recommendationCostH',
    sortBy: 'DESC',
  },
  INSTALLATION_COST_LOW_TO_HIGH: {
    label: 'Installation Cost: Low to High',
    value: 'INSTALLATION_COST_LOW_TO_HIGH',
    sortOn: 'recommendationCostL',
    sortBy: 'ASC',
  },
  CARBON_SAVING_HIGH_TO_LOW: {
    label: 'Carbon Saving: High to Low',
    value: 'CARBON_SAVING_HIGH_TO_LOW',
    sortOn: 'recommendationCarbonSaving',
    sortBy: 'DESC',
  },
  CARBON_SAVING_LOW_TO_HIGH: {
    label: 'Carbon Saving: Low to High',
    value: 'CARBON_SAVING_LOW_TO_HIGH',
    sortOn: 'recommendationCarbonSaving',
    sortBy: 'ASC',
  },
};

export const MASTERS_TABS = {
  HOUSE_TYPES: {
    key: 'house-types',
    label: 'House Types',
  },
  HOUSE_SHAPES: {
    key: 'house-shapes',
    label: 'House Shapes',
  },
  ROOF_TYPES: {
    key: 'roof-types',
    label: 'Roof Types',
  },
  ROOF_EXTENSIONS: {
    key: 'roof-extension',
    label: 'Roof Extensions',
  },
  MATERIAL_CATEGORIES: {
    key: 'material-categories',
    label: 'Material Categories',
  },
  HEATING_SYSTEMS: {
    key: 'heating-systems',
    label: 'Heating Systems',
  },
};
